import * as React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CardActions,
  Grid
} from "@material-ui/core"
import { navigate } from "gatsby"

import okImg from "../../assets/undraw/undraw_cancel_u1it.svg"

const styles = (theme: Theme) =>
  createStyles({
    card: {
      width: "450px"
    }
  })
interface StripeCanceledProps extends WithStyles<typeof styles> {}

const StripeCanceled = (props: StripeCanceledProps) => {
  const { classes } = props
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: "100vh" }}>
      <Grid item xs={3}>
        <Card className={classes.card}>
          <CardActionArea onClick={() => navigate("/stripe-checkout")}>
            <CardMedia component="img" alt="Payment failed" height="300" image={okImg} title="try again" />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Payment Failed
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Unfortunately your payment did not work
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" onClick={() => navigate("/stripe-checkout")}>
              Try Again
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}
export default withStyles(styles)(StripeCanceled)
